import React from 'react'
import SEO from '../components/seo.js'
import { Link } from 'gatsby'

const NotFoundPage = () => (
    <div>
        <SEO title='404' />
        <div className='w-full h-full flex items-center justify-center '>
            <div className='max-w-sm m-8'>
                <div className='text-black text-5xl font-black'>404</div>
                <p className='text-grey-darker text-2xl font-light mb-8 leading-normal'>
                    Sorry, the page you accessed could not be found.
                </p>
                <Link
                    replace
                    to='/'
                    className='bg-transparent text-grey-darkest font-bold uppercase tracking-wide py-3 px-6 border-2 border-gray-300 hover:border-gray-800 rounded'
                >
                    Go Home
                </Link>
            </div>
        </div>
    </div>
)

export default NotFoundPage
